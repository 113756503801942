import React, { useState, useEffect } from 'react';

import { adminGet, adminPost, adminDelete } from '../utils/requests';

import style from './SiteSettings.module.scss';

const isValidEmail = (email: string): boolean => {
  const trimmed = email.trim();
  const at_pos = email.indexOf('@');
  return trimmed.length > 3 && at_pos > 0 && at_pos < trimmed.length - 1;
};

type Props = { siteId: string };

const NotificationSettings = ({ siteId }: Props): JSX.Element => {
  const [emails, setEmails] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState<string>('');
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    adminGet<any[]>(`/sites/${siteId}/notification_emails/`)
      .then(setEmails)
      .catch(setError);
  }, [siteId]);

  const removeEmail = (email: string) => {
    // TODO: make the request
    adminDelete(`/sites/${siteId}/notification_emails/${email}`)
      .then(() => setEmails(emails.filter(e => e !== email)))
      .catch(setError);
  };

  const addEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    adminPost(`/sites/${siteId}/notification_emails/`, {
      email: newEmail
    })
      .then(() => {
        setEmails([...emails, newEmail]);
        setNewEmail('');
      })
      .catch(setError);
  };
  return (
    <React.Fragment>
      <h2>Information</h2>
      <p>Your site ID is</p>
      <pre><code>{siteId}</code></pre>
      <p>Please refer to <a href="https://remark.ninja/docs">the documentation</a> to set up your site.</p>
      <h2>Notifications</h2>
      <p>
        Also send new comment notifications to the following email addresses:
      </p>
      {!!error && <p className="error">{error.message}</p>}
      <ul className={style.emailList}>
        {emails.map(email => (
          <li key={email}>
            {email}{' '}
            <button
              className={style.deleteEmail}
              onClick={() => removeEmail(email)}
            >
              ✗
            </button>
          </li>
        ))}
        <li>
          <form onSubmit={addEmail}>
            <input
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
            />
            <button type="submit" disabled={!isValidEmail(newEmail)}>
              Add
            </button>
          </form>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default function SiteSettings(props: Props): JSX.Element {
  return (
    <div className={style.mainContent}>
      <NotificationSettings siteId={props.siteId} />
    </div>
  );
}
