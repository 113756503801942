import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

import { adminGet, adminPatch } from '../utils/requests';
import style from './SiteComments.module.scss';

type Props = { siteId: string };
type Comment = {
  id: number;
  body: string;
  threadSlug: string;
  createdAt: string;
};

export default function SiteComments(props: Props): JSX.Element {
  const [comments, setComments] = useState<undefined | Comment[]>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedComments, setSelectedComments] = useState<Set<number>>(
    new Set()
  );
  const [start, setStart] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);

  const selectComment = (id: number): void => {
    const s = new Set(selectedComments);
    s.add(id);
    setSelectedComments(s);
  };

  const unselectComment = (id: number): void => {
    const s = new Set(selectedComments);
    s.delete(id);
    setSelectedComments(s);
  };

  const allSelected = (): boolean => {
    return comments !== undefined && selectedComments.size === comments.length;
  };

  const toggleAllSelected = (): void => {
    if (allSelected()) {
      setSelectedComments(new Set());
    } else if (comments) {
      setSelectedComments(new Set(comments.map(c => c.id)));
    }
  };

  const deleteComments = async (): Promise<void> => {
    await adminPatch(`/sites/${props.siteId}/comments`, {
      action: 'delete',
      ids: Array.from(selectedComments)
    });
    if (comments !== undefined) {
      setComments(comments.filter(c => !selectedComments.has(c.id)));
    }
    setSelectedComments(new Set());
  };

  const prevPage = () => {
    setStart(Math.max(start - limit, 0));
  };
  const nextPage = () => {
    setStart(start + limit);
  };

  useEffect(() => {
    adminGet<Comment[]>(`/sites/${props.siteId}/comments`, { start, limit })
      .then(comments => {
        setComments(comments);
      })
      .catch(e => {
        setError(e.message);
      });
  }, [props.siteId, start, limit]);

  if (comments === undefined) {
    return (
      <div>
        Loading comments ...
        {error && <div>{error}</div>}
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div>
          <button
            disabled={selectedComments.size === 0}
            onClick={deleteComments}
          >
            Delete
          </button>
        </div>
        <table>
          <colgroup>
            <col className={style.checkboxCol} />
            <col className={style.contentCol} />
            <col className={style.threadCol} />
            <col className={style.timeCol} />
          </colgroup>
          <thead>
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={comments && comments.length > 0 && allSelected()}
                  onChange={toggleAllSelected}
                />
              </td>
              <td>Content</td>
              <td>Thread</td>
              <td>Time</td>
            </tr>
          </thead>
          <tbody>
            {comments.map(c => (
              <tr key={c.id}>
                <td className={style.rowCheckbox}>
                  <input
                    type="checkbox"
                    checked={selectedComments.has(c.id)}
                    onChange={e => {
                      e.target.checked
                        ? selectComment(c.id)
                        : unselectComment(c.id);
                    }}
                  />
                </td>
                <td className={style.commentBody}>{c.body}</td>
                <td>{c.threadSlug}</td>
                <td>
                  <Moment fromNow>{c.createdAt}</Moment>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={style.pagination}>
          <button disabled={start === 0} onClick={prevPage}>
            Previous
          </button>
          <span>
            <input
              type="number"
              value={limit}
              onChange={e => setLimit(parseInt(e.target.value))}
            />{' '}
            comments / page
          </span>
          <button disabled={comments.length < limit} onClick={nextPage}>
            Next
          </button>
        </div>
      </React.Fragment>
    );
  }
}
