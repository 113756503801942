import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { adminPost } from '../utils/requests';
import style from './CreateSite.module.scss';

function CreateSite(): JSX.Element {
  const [siteName, setSiteName] = useState<string>('');
  const [error, setError] = useState('');
  const history = useHistory();

  const submit = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    const properSiteName = siteName.trim();
    if (properSiteName.length > 1) {
      try {
        await adminPost('/sites', { name: siteName });
        history.push('/sites');
      } catch (e) {
        setError(e.message);
      }
    } else {
      setError('The site name cannot be empty.');
    }
  };

  return (
    <div className={style.formContainer}>
      <h1>Add a new site</h1>
      {!!error && <div className={style.error}>{error}</div>}
      <div className={style.formRow}>
        <label htmlFor="site-name">Site name</label>
        <input
          id="site-name"
          value={siteName}
          onChange={e => setSiteName(e.target.value)}
        />
      </div>
      <div className={style.formRow}>
        <input type="submit" value="Create" onClick={submit} />
      </div>
    </div>
  );
}

export default CreateSite;
