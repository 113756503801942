import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { adminGet } from '../utils/requests';

import style from './SiteList.module.scss';

type Site = { name: string; id: string };

export default function SiteList({
  match
}: RouteComponentProps<void>): JSX.Element {
  const [sites, setSites] = useState<Site[] | undefined>(undefined);
  const [error, setError] = useState('');

  if (sites === undefined) {
    adminGet<Site[]>('/sites')
      .then(setSites)
      .catch(e => setError(e.message));
    return <div>Loading</div>;
  } else if (sites.length === 0) {
    return (
      <div>
        <div>{error}</div>
        <div>
          You have no site. <Link to={`${match.url}/new`}>Add one now</Link>.
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>{error}</div>
        <div>
          {sites.map(site => (
            <div className={style.site} key={site.id}>
              <Link to={`${match.url}/${site.id}/comments`}>{site.name}</Link>
            </div>
          ))}
        </div>
        <div className={style.addNew}>
          <Link to={`${match.url}/new`}>+ Add</Link>
        </div>
      </div>
    );
  }
}
