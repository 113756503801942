import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { adminPost } from '../utils/requests';

import style from './LoginPage.module.scss';

export default function LoginPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  if (success) {
    return <Redirect push to="/" />;
  }

  const login = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    try {
      await adminPost('/login', { email, password });
      setSuccess(true);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className={style.formContainer}>
      <h1>Login</h1>
      <form>
        <div className={style.formRow}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={style.formRow}>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div>{error}</div>
        <div className={style.formRow}>
          <input type="submit" value="Login" onClick={login} />
          <Link className={style.registerLink} to="/register">
            Register
          </Link>
        </div>
      </form>
    </div>
  );
}
