import React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import SiteComments from './SiteComments';
import SiteSettings from './SiteSettings';
import SiteThreads from './SiteThreads';
import style from './SitePage.module.scss';

type MatchParams = { siteId: string };
type Props = RouteComponentProps<MatchParams>;

export default function SitePage({ match }: Props): JSX.Element {
  const { siteId } = match.params;
  const sitePath = `/sites/${siteId}`;
  return (
    <div className={style.sitePage}>
      <nav className={style.sidebar}>
        <ul>
          <li>
            <NavLink to={`${sitePath}/comments`}>Comments</NavLink>
          </li>
          <li>
            <NavLink to={`${sitePath}/threads`}>Threads</NavLink>
          </li>
          <li>
            <NavLink to={`${sitePath}/settings`}>Settings</NavLink>
          </li>
        </ul>
      </nav>
      <div className={style.main}>
        <Switch>
          <Route
            path={`${match.path}/comments`}
            render={() => <SiteComments siteId={siteId} />}
          />
          <Route
            path={`${match.path}/threads`}
            render={() => <SiteThreads siteId={siteId} />}
          />
          <Route
            path={`${match.path}/settings`}
            render={() => <SiteSettings siteId={siteId} />}
          />
        </Switch>
      </div>
    </div>
  );
}
