import React, { useState } from 'react';
import { Redirect, Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import { adminGet, adminPost } from '../utils/requests';
import SiteList from './SiteList';
import CreateSite from './CreateSite';
import SitePage from './SitePage';

import style from './Home.module.scss';

type SiteAdmin = { email: string };

const gravatar = (email: string): JSX.Element => {
  return (
    <img
      alt="gravatar"
      src={`https://www.gravatar.com/avatar/${md5(email)}?s=28&d=robohash`}
    />
  );
};

export default function Home(): JSX.Element {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [shouldLogin, setShouldLogin] = useState(false);

  const logout = (): void => {
    adminPost<void>('/logout').finally(() => setShouldLogin(true));
  };
  if (shouldLogin) {
    return <Redirect push to="/login" />;
  } else if (!email) {
    adminGet<SiteAdmin>('/me')
      .then(json => setEmail(json.email))
      .catch(() => setShouldLogin(true));
    return <div>Logging in ...</div>;
  } else {
    return (
      <div className={style.pageContainer}>
        <header className={style.topNav}>
          <Link to="/" className={style.logo}>
            <h1>remark ninja</h1>
          </Link>
          <nav className={style.accountNav}>
            {gravatar(email)}
            <span>{email}</span>
            <span>
              <button onClick={logout}>Logout</button>
            </span>
          </nav>
        </header>
        <Switch>
          <Route path="/sites/new" component={CreateSite} />
          <Route path="/sites/:siteId" component={SitePage} />
          <Route path="/sites" component={SiteList} />
          <Route exact path="/" component={() => <Redirect to="/sites" />} />
        </Switch>
      </div>
    );
  }
}
