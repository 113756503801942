import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

import { adminGet } from '../utils/requests';

type Props = { siteId: string };
type Thread = {
  userThreadId: string;
  commentCount: number;
  lastCommentCreatedAt: string;
};

export default function SiteThreads(props: Props): JSX.Element {
  const [threads, setThreads] = useState<undefined | Thread[]>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  if (threads === undefined) {
    adminGet<Thread[]>(`/sites/${props.siteId}/threads`)
      .then(threads => {
        setThreads(threads);
      })
      .catch(e => {
        setError(e.message);
      });
    return (
      <div>
        Loading threads ...
        {error && <div>{error}</div>}
      </div>
    );
  } else {
    return (
      <table>
        <thead>
          <tr>
            <td>Thread id</td>
            <td>Comments</td>
            <td>Last comment at</td>
          </tr>
        </thead>
        <tbody>
          {threads.map(t => (
            <tr key={t.userThreadId}>
              <td>{t.userThreadId}</td>
              <td>{t.commentCount}</td>
              <td>
                <Moment fromNow>{t.lastCommentCreatedAt}</Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
