export async function adminPost<T = object>(path = '', data = {}): Promise<T> {
  const res = await fetch('/api/1/admin' + path, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const json = await res.json();
  if (res.status >= 400) {
    throw new Error(json.message);
  } else {
    return json;
  }
}

export async function adminGet<T = object>(path = '', params = {}): Promise<T> {
  const url = new URL(window.location.origin + '/api/1/admin' + path);
  url.search = new URLSearchParams(params).toString();
  const res = await fetch(url.toString(), {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const json = await res.json();
  if (res.status >= 400) {
    throw new Error(json.message);
  } else {
    return json;
  }
}

export async function adminPatch<T = object>(path = '', data = {}): Promise<T> {
  const res = await fetch('/api/1/admin' + path, {
    method: 'PATCH',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const json = await res.json();
  if (res.status >= 400) {
    throw new Error(json.message);
  } else {
    return json;
  }
}

export async function adminDelete<T = object>(path = ''): Promise<T> {
  const res = await fetch('/api/1/admin' + path, {
    method: 'DELETE',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const json = await res.json();
  if (res.status >= 400) {
    throw new Error(json.message);
  } else {
    return json;
  }
}

// TODO: refactor those to remove repetitive code
